.page--home {
  .page__content {
    margin-right: 0;
    width: 50vw;
  }

  .home__actions {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
  }
}
