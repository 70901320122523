.page--how {
  .page__content {
    width: 100%;
  }

  .how__intro {
    h2, h3 {
      margin: var(--gap--4) 0 var(--gap--2);
    }
    p {
      margin: 0 0 var(--gap--2);
    }
  }

  .how__actions {
    align-items: flex-end;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 100%;
  }
}
