.example {
  border-bottom: 1px solid var(--color--neutrals-1);
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: var(--gap--5) 0;

  &:first-child {
    border-top: 1px solid var(--color--neutrals-1);
  }

  &__content {
    flex-grow: 1;
  }

  &__description {
    margin: var(--gap--2) 0 0;
    padding-right: var(--gap--10);
  }
}
