@import './animations/vars';
@import './boxing/vars';
@import './colors/vars';
@import './typography/vars';

:root {
  @each $name, $value in $sections {
    --section--#{$name}: #{$value};
  }

  @for $i from 0 to $gaps {
    --gap--#{$i}: #{$i * $gap};
  }

  @each $name, $value in $colors {
    --color--#{$name}: #{$value};
  }

  @each $name, $value in $fontSizes {
    --fs--#{$name}: #{$value};
  }

  --ff--regular: #{$font--regular};
  --ff--special: #{$font--special};

  --transition-duration--slow: #{$default-transition-duration-slow};
  --transition-duration: #{$default-transition-duration};
  --transition-duration--fast: #{$default-transition-duration-fast};

  --header-height: 100px;

  @media screen and (min-width: map-get($map: $sections, $key: 5)) {
    --header-height: 125px;
  }

  @media print {
    --header-height: 70px;
  }
}

@import './animations/animations.scss';
@import './boxing/boxing.scss';
@import './colors/colors.scss';
@import './typography/typography.scss';
