.navigation {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  ul, li {
    list-style: none;
  }

  &__wrapper {
    position: relative;
  }

  &__trigger {
    font-size: var(--fs--h1);
    position: relative;
    z-index: 10;
  }

  &__list {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 0;
    opacity: 0;
    padding: 0;
    padding-right: var(--gap--5);
    transform: translateX(120%);
    transition: transform var(--transition-duration) 100ms, opacity var(--transition-duration);
  }

  &__item {
    margin-left: var(--gap--2);
    margin-right: var(--gap--2);

    @media screen and (min-width: 1024px) {
      margin-left: var(--gap--3);
      margin-right: var(--gap--3);
    }
    @media screen and (min-width: 1240px) {
      margin-left: var(--gap--6);
      margin-right: var(--gap--6);
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;
    transition: color var(--transition-duration);

    &:hover {
      color: var(--color--primary);
    }

    &--active {
      font-weight: 600;
    }
  }

  &__active {
    font-weight: bold;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    right: 100px;
    transition: opacity var(--transition-duration);
  }

  &--open {
    .navigation__list {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0ms, 150ms;
    }
    .navigation__active {
      opacity: 0;
    }
  }
}

@media print {
  .navigation { display: none; }
}
