.page--start {
  .page__content {
    width: 100%;
  }

  .steps {
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
  }

  .step {
    text-align: center;
    width: 100%;

    .icon {
      height: 4em;
      width: 4em;
    }

    &__icon {
      img {
        display: block;
        height: auto;
        margin: 0 auto;
        width: 4em;
      }
    }
  }

  .icon--arrow {
    color: var(--color--brand-1);
    font-size: 4em;
    margin-left: var(--gap--4);
    margin-right: var(--gap--4);
    width: 4em;
  }

  .start__input {
    width: 100%;

    .form__input--textarea {
      font-size: var(--fs--medium);
    }
  }

  .form__input--textarea {
    font-size: var(--fs--large);
  }

  .start__actions {
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
  }

  .footer {
    display: none;
    // pointer-events: none;
    // position: absolute;
    // left: 0;
    // top: auto;
    // bottom: 0;

    // &__icons {
    //   justify-content: flex-start;
    //   margin-left: 0;
    //   pointer-events: all;
    // }

    // &__icon {
    //   margin-left: 0;
    //   margin-right: var(--gap--12);
    // }
  }
}
