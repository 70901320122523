.tooltip {
  --tooltip-bg: var(--color--neutrals-0);

  display: flex;
  flex-flow: column;
  position: absolute;
  top: 50%;
  transform: translate(-50%, 0);
  width: 400px;
  z-index: 999;

  &:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--tooltip-bg);
    display: block;
    content: '';
    height: 0;
    margin: 0 auto;
    position: relative;
    width: 0;
    z-index: 10;
  }

  &__content {
    background: var(--tooltip-bg);
    border: 1px solid var(--neutrals--2);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    font-size: var(--fs--small);
    padding: var(--gap--12) var(--gap--7) var(--gap--7);
    position: relative;
  }

  &__close {
    padding: 0;
    position: absolute;
    right: var(--gap--2);
    top: var(--gap--2);

    .button__icon {
      max-width: none;

      .icon--close {
        font-size: 2em;
      }
    }
  }

  &__actions {
    text-align: right;
  }

  &--top {
    transform: translate(-50%, -100%) translateY(-30px);

    &:before {
      border-bottom: none;
      border-top: 10px solid var(--tooltip-bg);
      order: 2;
    }
  }
}
