@import './vars';

html {
  font: 400 var(--fs--regular) / 1.5 var(--ff--regular);
}

strong {
  font-weight: 600;
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.heading--0 {
  font-size: var(--fs--h0);
}

h1, .heading--1 {
  font-size: var(--fs--h1);
}
h2, .heading--2 {
  font-size: var(--fs--h2);
}
h3, .heading--3 {
  font-size: var(--fs--h3);
}
h4, .heading--4 {
  font-size: var(--fs--h4);
}

.p--0 { font-size: var(--fs--large); }
.p--1 { font-size: var(--fs--medium); }
.p--2 { font-size: var(--fs--normal); }
.p--3 { font-size: var(--fs--small); }

@each $name, $value in $fontSizes {
  .fs--#{$name}{ font-size: var(--fs--#{$name}); }
}

.bold {
  font-weight: 600;
}
