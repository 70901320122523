.footer {
  margin-top: auto;
  padding-top: var(--gap--5);
  width: 100vw;

  &__content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: var(--gap--4);
    padding-top: var(--gap--4);
  }

  &__icons {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-left: auto;
  }

  &__icon {
    margin-left: var(--gap--12);
    width: 150px;
  }
}
