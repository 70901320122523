.infolink {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: var(--gap--2);
  margin-right: var(--gap--2);
  text-align: center;

  &__link {
    color: inherit;
    text-decoration: none;
  }
  &__icon {
    font-size: 10em;
  }

  &:first-child() {
    margin-left: 0;
  }
  &:last-child() {
    margin-right: 0;
  }
}
