html, body {
  margin: 0;
  min-height: 100vh;
  padding: 0;
  box-sizing: border-box;
}

body {
  * {
    box-sizing: border-box;
  }
}

@for $i from 0 to $gaps {
  .mt--#{$i} { margin-top: var(--gap--#{$i}); }
  .mb--#{$i} { margin-bottom: var(--gap--#{$i}); }
  .ml--#{$i} { margin-left: var(--gap--#{$i}); }
  .mr--#{$i} { margin-right: var(--gap--#{$i}); }

  .mx--#{$i} { margin-left: var(--gap--#{$i}); margin-right: var(--gap--#{$i}); }
  .my--#{$i} { margin-bottom: var(--gap--#{$i}); margin-top: var(--gap--#{$i}); }

  .pt--#{$i} { padding-top: var(--gap--#{$i}); }
  .pb--#{$i} { padding-bottom: var(--gap--#{$i}); }
  .pl--#{$i} { padding-left: var(--gap--#{$i}); }
  .pr--#{$i} { padding-right: var(--gap--#{$i}); }

  .px--#{$i} { padding-left: var(--gap--#{$i}); padding-right: var(--gap--#{$i}); }
  .py--#{$i} { padding-bottom: var(--gap--#{$i}); padding-top: var(--gap--#{$i}); }
}
.mx--auto { margin-left: auto; margin-right: auto; }
.m--auto { margin: auto; }
.ml--auto { margin-left: auto; }
.mr--auto { margin-right: auto; }

[class*="section"] {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gap--5);
  padding-right: var(--gap--5);
}

@each $name, $value in $sections {
  .section--#{$name} {
    width: calc(var(--section--#{$name}) + var(--gap--5) + var(--gap--5));
  }
}

.section--full {
  width: 100vw;
}

img {
  display: block;
  width: 100%;
}

p {
  margin-bottom: var(--gap--2);
  margin-top: 0;
}

figure {
  padding: 0;
  margin: 0;
  position: relative;
}
