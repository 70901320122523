.icon {
  display: inline-flex;
  height: 1em;
  width: 1em;

  svg {
    height: auto;
    display: block;
    width: 100%;
  }
}

.icon--menu {
  align-items: flex-end;
  display: inline-flex;
  flex-flow: column;
  height: auto;
  padding: 0.3125em 0;
  position: relative;
  justify-content: center;
  width: 1em;
  // z-index: 1;

  &:before, &:after, .middle-bar {
      background-color: currentColor;
      border-radius: 2px;
      content: '';
      display: block;
      height: 0.1875em;
      left: 0;
      position: absolute;
      width: 100%;
      // z-index: 1;
  }

  .middle-bar {
      opacity: 1;
      position: relative;
      transition: opacity var(--transition-duration), background-color var(--transition-duration);
      width: 75%;
  }

  &:before {
      top: 0;
      transition: transform var(--transition-duration), top var(--transition-duration), background-color var(--transition-duration);
  }

  &:after {
      bottom: 0;
      transition: transform var(--transition-duration), bottom var(--transition-duration), background-color var(--transition-duration);
  }

  &.open {
      &:before {
          transform: rotate(45deg);
          top: 0.3125em;
      }

      .middle-bar {
          opacity: 0;
      }

      &:after {
          bottom: 0.3125em;
          transform: rotate(-45deg);
      }
  }

  &:focus {
      outline: none;
  }
}

// todo: all icons same size 40 x 40
.icon--chevron {
  width: 2em;
}
