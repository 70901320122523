.page--tool {
  height: 100%;

  .page__content {
    margin-top: calc(var(--gap--2) * -1);
    max-width: var(--section--9);
    width: 100%;
  }

  .tool {
    position: relative;
    width: 100%;

    &__actions {
      bottom: 0;
      display: flex;
      flex-flow: row nowrap;
      left: 0;
      padding: var(--gap--2);
      position: absolute;
      top: auto;
    }

    &__casus {
      display: flex;
      flex-flow: column;
      width: 100%;
      margin-top: 0;
    }
  }

  .icon {
    cursor: pointer;
  }

  .footer {
    display: none;
  }
}

.tool__content {
  border: 1px solid var(--color--neutrals-2);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-top: 50%;
}

.tool__stage {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.menu {
  background: var(--color--neutrals-0);
  border-left: 1px solid var(--color--neutrals-2);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform var(--transition-duration);
  width: 450px;
  z-index: 10;

  &__trigger {
    cursor: pointer;
    left: 0;
    position: absolute;
    transform: translate(-60%, -50%);
    top: 50%;

    .button__icon {
      align-items: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin-right: var(--gap--5);
      width: 3em;
    }
  }

  &--open {
    transform: translateX(0%);

    .menu__trigger {
      .icon {
        transform: rotate(180deg);
      }
    }
  }
}

.menu__accordeon {
  align-items: flex-start;
  background: var(--color--neutrals-0);
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: auto;
  position: relative;

  .item__meta {
    flex-grow: 1;
  }

  .item__trigger {
    align-items: center;
    background: var(--color--brand-2);
    border-bottom: 1px solid var(--color--neutrals-0);
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: var(--gap--2) var(--gap--5);
  }

  .item__number {
    margin-right: var(--gap--5);
    font-size: var(--fs--h0);
  }

  .item__links {
    overflow: hidden;
    max-height: 0;
    transition: max-height var(--transition-duration) cubic-bezier(0, 1, 0.5, 1);
  }

  .item__link {
    align-items: center;
    border-bottom: 1px solid var(--color--neutrals-2);
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    padding: var(--gap--2) var(--gap--5);
    position: relative;

    &:hover {
      background-color: var(--color--hover-light);
    }
  }
  .link__meta {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    margin-right: var(--gap--5);
  }

  .link__icon {
    width: 48px;
  }

  .link__label {
    margin-left: var(--gap--5);
  }

  .link__info {
    background: var(--color--neutrals-0);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 4px;
    height: auto;
    padding: var(--gap--5);
    position: absolute;
    right: var(--gap--5);
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    z-index: 100;

    display: none;
  }

  .link__actions {
    display: flex;
    flex-flow: row nowrap;

    .icon {
      cursor: pointer;
      height: 2em;
      margin: 0 var(--gap--1);
      outline: none !important;
      width: 2em;

      &--plus {
        pointer-events: none;
      }
    }
  }

  .accordeon__item {
    width: 100%;

    &--open {
      flex-grow: 1;

      .item__links {
        overflow: auto;
        max-height: 100vh;
      }
    }
  }
}

.tool__export {
  border: 1px solid var(--color--neutrals-2);
  border-radius: 6px;
  display: none;
  margin-top: 0;
  width: 100%;

  img {
    display: block;
    //max-height: calc(100vh - var(--header-height));
    // object-fit: contain;
    width: 100%;
  }
}

@media print {
  @page {
    size: A4 portrait;
    size: 210mm 287mm;
    margin: 0;
  }

  html, body {
    height: 210mm;
    width: 297mm;
  }

  .menu { display: none !important; }
  .tool__actions { display: none !important; }
  .tool__content { display: none !important; }
  .tool__casus { display: none !important; }
  .tool__export { display: block !important; }

  .page--tool {
    height: auto;
    min-height: 0;

    .page__content {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
