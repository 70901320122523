.form {
  &__item {
    display: flex;
    flex-flow: column;
    position: relative;

    &--checkbox, &--radio {
      flex-flow: row nowrap;
    }
  }

  &__label {
    font-weight: bold;
  }

  &__input {
    appearance: none;
    background: var(--color--neutrals-0);
    border: 1px solid var(--color--neutrals-1);
    border-radius: 6px;
    color: inherit;
    display: block;
    font: inherit;
    padding: var(--gap--1) var(--gap--2);
    width: 100%;

    &--textarea {
      min-height: 3em;
      resize: vertical;
      padding: var(--gap--3) var(--gap--4);
    }

    &--checkbox {
      height: 0;
      left: -9999px;
      opacity: .1;
      position: absolute;
      top: -9999px;
      width: 0;

      & + label {
        font-weight: normal;
        display: flex;
        flex-flow: row nowrap;

        &:before {
          border: 1px solid var(--color--neutrals-3);
          border-radius: 2px;
          content: '';
          display: block;
          height: 1em;
          margin-right: var(--gap--2);
          margin-top: 2px;
          width: 1em;
        }
      }

      &:checked + label {
        &:before {
          background: var(--color--primary);
        }
      }
    }

    &:focus {
      border-color: var(--color--neutrals-3);
      outline: none;
    }
  }
}
