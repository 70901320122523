.logo {
  align-items: center;
  display: inline-flex;
  text-decoration: none;

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
}
