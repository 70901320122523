.button {
  --button-shadow: 8px 8px 20px rgba(0,0,0,0.2);
  --button-border: 4px solid var(--color--neutrals-0);

  align-items: center;
  appearance: none;
  backface-visibility: hidden;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  flex-flow: row nowrap;
  font: inherit;
  justify-content: center;
  line-height: 1;
  outline: none !important;
  padding: 0;
  text-decoration: none;

  &[disabled] {
    filter: grayscale(100%);
    opacity: .6;
    pointer-events: none;
  }

  &:before {
    content: '';
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &:hover {
    filter: brightness(95%);
  }

  &__label {
    max-width: 6.2em;
    text-align: center;
  }

  &__icon {
    display: flex;
    height: auto;
    min-width: 1em;

    svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &--normal {
    font-size: var(--fs--medium);
    padding: .8em 1.375em .8em 1.375em;
  }

  &--small {
    padding: .4em 0.6875em .45em 0.6875em;
    font-size: var(--fs--normal);

    &.button--round {
      padding: 0;

      .button__label {
        padding: .7em;
      }
    }
  }

  &--large {
    font-size: var(--fs--large);
  }

  &--default {
    &.button--primary {
      background-color: var(--color--primary);
      color: var(--color--text-light);
    }
    &.button--secondary {
      background-color: var(--color--secondary);
      color: var(--color--text-light);
    }
    &.button--light {
      background-color: var(--color--neutrals-0);
      color: var(--color--primary);
    }
    &.button--dark {
      background-color: var(--color--neutrals-9);
      color: var(--color--text-light);
    }
    &.button--positive {
      background-color: var(--color--positive);
      color: var(--color--text-light);
    }
    &.button--negative {
      background-color: var(--color--negative);
      color: var(--color--text-light);
    }
    &.button--shadow {
      box-shadow: var(--button-shadow);
    }
    &.button--border {
      border: var(--button-border);
    }
  }

  &--round {
    background: transparent;
    padding: 0;
    position: relative;

    .button__label, .button__icon {
      padding: .5em;
      position: relative;
    }

    &:before {
      border-radius: 100%;
      padding-top: 100%;
      height: 0;
      width: 100%;
    }

    &.button--primary {
      color: var(--color--text-light);

      &:before {
        background-color: var(--color--primary);
      }
    }
    &.button--secondary {
      color: var(--color--text-light);

      &:before {
        background-color: var(--color--secondary);
      }
    }
    &.button--light {
      color: var(--color--primary);

      &:before {
        background-color: var(--color--neutrals-0);
      }
    }
    &.button--dark {
      color: var(--color--text-light);

      &:before {
        background-color: var(--color--neutrals-9);
      }
    }
    &.button--positive {
      color: var(--color--text-light);

      &:before {
        background-color: var(--color--positive);
      }
    }
    &.button--negative {
      color: var(--color--text-light);

      &:before {
        background-color: var(--color--negative);
      }
    }
    &.button--shadow {
      &:before {
        box-shadow: var(--button-shadow);
      }
    }
    &.button--border {
      &:before {
        border: var(--button-border);
      }
    }
  }

  &--naked {
    background-color: transparent !important;

    &.button--primary {
      color: var(--color--primary);
    }
    &.button--secondary {
      color: var(--color--secondary);
    }
    &.button--positive {
      color: var(--color--positive);
    }
    &.button--negative {
      color: var(--color--negative);
    }
  }
}
