@import './vars';

body {
  color: var(--color--text-dark);
}

@each $name, $value in $colors {
  .bg--#{$name} { background-color: var(--color--#{$name}); }
  .fc--#{$name} { color: var(--color--#{$name}); }
}

.error {
  color: var(--color--negative);
}

a { color: inherit; }
