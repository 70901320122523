@import '../../00_fundaments/boxing/vars';

.header {
  --logo-width: 150px;

  height: var(--header-height);
  left: 0;
  margin-top: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100vw;

  &__content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: var(--gap--4);
  }

  &__logo {
    width: var(--logo-width);
  }

  &__nav {
    flex-grow: 1;
    margin-left: var(--gap--5);
  }

  @media screen and (min-width: map-get($map: $sections, $key: 5)) {
    --logo-width: 300px;
  }

  @media print {
    --logo-width: 100px;
  }
}
