.page {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  min-height: 100vh;

  &__content {
    align-items: stretch;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--section--8);
    padding-top: var(--header-height);

    .image {
      img {
        display: block;
        height: auto;
        max-width: 100%;
      }
    }

    .video {
      position: relative;
      margin-bottom: var(--gap--5);
      margin-top: var(--gap--5);

      &:before {
        display: block;
        content: '';
        height: 0;
        padding-top: 56.26%;
      }

      iframe {
        border: none;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .richtext {
    h1, h2, h3, p {
      margin-top: 0;
      margin-bottom: var(--gap--5);
    }

    h1, h2, h3 {
      color: var(--color--brand-0);
    }

    blockquote {
      background: var(--color--quote-bg);
      margin: 0;
      padding: var(--gap--5);
      font-size: var(--fs--h1);
      font-style: italic;
    }
  }

  &--bg {
    background: url(./bg.jpg) no-repeat center top / cover;
  }
}
