.page--help {
  .page__content {
    align-items: center;
    width: 100%;
  }

  .infolinks {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
}
